import React from 'react'
import {
  graphql,
} from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import PageHero from '../../components/Blocks/PageHero'
import BorderedBlocks from '../../components/Blocks/BorderedBlocks'

import Section from '../../components/Base/Section'
import SectionWrap from '../../components/Base/Section/Wrap'
import Title from '../../components/Base/Title'
import Paragraph from '../../components/Base/Paragraph'

import IconCustom from '../../components/Base/Icon/Custom'

import {
  Page, Main, ButtonLink,
} from '../../components/StyledComponents'
import Text from '../../components/Base/Text'
import Testimonial from '../../components/Blocks/Testimonial'
import PricingModal from '../../components/Blocks/Modals/Pricing/PricingModal'
import useModal from '../../components/Blocks/Modals/Pricing/useModal'
import BottomOverlay from '../../components/Blocks/BottomOverlay'

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "page-hero/hero-benefits-small.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    desktopImage: file(
      relativePath: { eq: "page-hero/hero-benefits.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default ({
  data,
}) => {
  const {
    isShowing, toggle,
  } = useModal()
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 800px)',
    },
  ]

  return (
    <Layout>
      <SEO
        title='Benefits'
        description='ChartMedic helps save time and frustration across the entire clinic organization.'
      />
      <Page>
        <Header />
        <Main>
          <PageHero
            background={sources}
            title='Benefits'
          />
          <SectionWrap theme='grey'>
            <Section center>
              <IconCustom theme='cost' margined />
              <Title level={2}>Time is Money</Title>
              <Paragraph theme='short'>
                <Text theme='medium'>
                  ChartMedic helps save time and frustration across the entire
                  clinic organization.
                </Text>
              </Paragraph>
              <BorderedBlocks
                borderColor='#f6f6f6'
                blocks={[
                  {
                    id: '1',
                    colTitle: 'Less Time Writing.',
                    colDesc:
                      'ChartMedic lets patients answer  questions in just a few taps. No more filling in repetitive forms by hand. This makes for a better check-in experience.',
                  },
                  {
                    id: '2',
                    colTitle: 'Less Time Filing.',
                    colDesc:
                      'Interviews completed through ChartMedic are instantly transferred to Epic. This eliminates the need to review and transcribe paper questionnaires.',
                  },
                  {
                    id: '3',
                    colTitle: 'Less Time Writing.',
                    colDesc:
                      'ChartMetic’s data collection methods mean the right information is captured in the right areas in less time.',
                  },
                ]}
              />
            </Section>
          </SectionWrap>
          <Section center>
            <IconCustom theme='everyone' margined />
            <Title level={2}>A Place for Everyone.</Title>
            <Paragraph theme='short'>
              <Text theme='medium'>
                ChartMedic offers roles for everyone within your clinic
                organization. From your front desk staff to your clinic
                administrator, we have it all covered.
              </Text>
            </Paragraph>
          </Section>
          <Testimonial
            quote='I have been so impressed ChartMedic. Our clinic is running more smoothly. Patients have easy check-in is, and our Providers love it. Thumbs up!'
            cite='Clinic Manager'
            location='Seattle, Wa'
          />
          <Section center>
            <IconCustom theme='target' margined />
            <Title level={2}>Accuracy is Important.</Title>
            <Paragraph theme='short'>
              <Text theme='medium'>
                Access to the right information can improve the accuracy of each
                diagnosis.
              </Text>
            </Paragraph>
            <BorderedBlocks
              borderColor='#f6f6f6'
              blocks={[
                {
                  id: '4',
                  colTitle: 'Interviews Designed by  Medical Professionals.',
                  colDesc:
                    'Our interviews were by medical professionals, so providers can feel confident that all the right information will be captured.',
                },
                {
                  id: '5',
                  colTitle: 'ChartMedic Asks all the Right Questions. ',
                  colDesc:
                    'Asking the right questions can be difficult with limited time. Missing key details may prove an issue when making an accurate diagnosis. ChartMedic helps relieve the uncertainty by collecting the right information.',
                },
                {
                  id: '6',
                  colTitle: 'Centrally Accessible.',
                  colDesc:
                    'ChartMedic makes the process of capturing health information efficient. And, It’s all there, in the cloud and accessible by key systems. All the right people will have access.',
                },
              ]}
            />
          </Section>
          <SectionWrap theme='grey'>
            <Section center>
              <IconCustom theme='airplane' margined />
              <Title level={2}>Hi Value, Flexible Plans</Title>
              <Paragraph>
                <Text theme='medium'>
                  Our pricing model is based on usage. Your organization will
                  only pay for what you use, nothing more. Plans are flexible,
                  and our introductory pricing starts at just $39.99 per month. And, you can cancel at any
                  time.
                  <br />
                  <br />
                  <button type='button' className='modal-link' onClick={toggle}>
                    <strong>View Pricing &gt;</strong>
                  </button>
                </Text>
              </Paragraph>
              <PricingModal isShowing={isShowing} hide={toggle} />
            </Section>
          </SectionWrap>
          <Section center theme='short'>
            <Title level={2} margined black>
              Are you ready to experience the ChartMedic difference?
            </Title>
            <ButtonLink to='/get-started'>Get Started</ButtonLink>
          </Section>
        </Main>
      </Page>
      <Footer />
      <BottomOverlay />
    </Layout>
  )
}
