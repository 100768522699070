// https://upmostly.com/tutorials/modal-components-react-custom-hooks

import React from 'react'
import ReactDOM from 'react-dom'

import {
  ButtonLink,
} from '../../../StyledComponents'
import {
  ModalsPricingWrapper,
  ModalsPricingContent,
  ModalsPricingTop,
  ModalIcon,
  ModalIntro,
  Rates,
  RateRow,
  RateTitle,
  RateData,
} from './styles'
import Title from '../../../Base/Title'
import Paragraph from '../../../Base/Paragraph'

require('./PricingModal.css')

const PricingModal = ({
  isShowing, hide,
}) => isShowing
  ? ReactDOM.createPortal(
    <React.Fragment>
      <div className='modal-overlay' />
      <div
        className='modal-wrapper'
        aria-modal
        aria-hidden
        tabIndex={-1}
        role='dialog'
      >
        <div className='modal'>
          <div className='modal-header'>
            <button
              type='button'
              className='modal-close-button'
              data-dismiss='modal'
              aria-label='Close'
              onClick={hide}
            >
              <span aria-hidden='true'>x</span>
            </button>
          </div>
          <ModalsPricingWrapper>
            <ModalsPricingContent>
              <ModalsPricingTop>
                <ModalIcon theme='airplane' />
                <Title level={2}>Flexible Pricing Plans</Title>
              </ModalsPricingTop>
              <ModalIntro theme='intro'>
                <RateTitle>Take advantage of our introductory pricing!</RateTitle><br />
                Pricing is calculated across all clinics within the medical
                organization. Fees are applied only if Providers use
                ChartMedic during the monthly billing period.
              </ModalIntro>
              <Rates>
                <RateRow>
                  <RateTitle>Active Providers</RateTitle>
                  <RateTitle>Monthly Fee</RateTitle>
                </RateRow>
                <RateRow>
                  <RateData>0</RateData>
                  <RateData>No Charge</RateData>
                </RateRow>
                <RateRow>
                  <RateData>1-5</RateData>
                  <RateData>
                    $39.99 <span>Per Active Provider</span>
                  </RateData>
                </RateRow>
                <RateRow>
                  <RateData>6-15</RateData>
                  <RateData>
                    $34.99 <span>Per Active Provider</span>
                  </RateData>
                </RateRow>
                <RateRow>
                  <RateData>16-25</RateData>
                  <RateData>
                    $29.99 <span>Per Active Provider</span>
                  </RateData>
                </RateRow>
                <RateRow>
                  <RateData>26-50</RateData>
                  <RateData>
                    $27.99 <span>Per Active Provider</span>
                  </RateData>
                </RateRow>
                <RateRow>
                  <RateData>51-100</RateData>
                  <RateData>
                    $24.99 <span>Per Active Provider</span>
                  </RateData>
                </RateRow>
                <RateRow>
                  <RateData>101-250</RateData>
                  <RateData>
                    $22.99 <span>Per Active Provider</span>
                  </RateData>
                </RateRow>
                <RateRow>
                  <RateData>251-500</RateData>
                  <RateData>
                    $19.99 <span>Per Active Provider</span>
                  </RateData>
                </RateRow>
                <RateRow>
                  <RateData>501 +</RateData>
                  <RateData>Call for Pricing</RateData>
                </RateRow>
              </Rates>
              <Paragraph>
                Flat rate plans are negotiable for large organizations.
              </Paragraph>
              <ButtonLink to='/'>Get Started</ButtonLink>
            </ModalsPricingContent>
          </ModalsPricingWrapper>
        </div>
      </div>
    </React.Fragment>,
    document.body,
  )
  : null

export default PricingModal
