import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import MEDIA from '../../../constants/media'
import COLORS from '../../../constants/colors'
import FONTS from '../../../constants/fonts'
import Text from '../../Base/Text'

// Bordered Blocks Components
const BlocksWrap = styled.div`
  margin: 50px 0;
  ${MEDIA.medium`
  display: flex;
  justify-content: space-between;
  `}
`
const BlockContent = styled.div`
  background: ${COLORS.WHITE};
  border: 4px solid #f6f6f6;
  padding: 3rem 2rem 4rem;
  max-width: 640px;
  margin: 0 auto 1rem;
  text-align: center;
  ${MEDIA.medium`
  flex: 1;
  margin: 0 .25rem 0;
  `}
`
const BlockTitle = styled.h4`
  color: ${COLORS.BLUE};
  font-size: ${FONTS.TITLEFOUR};
  font-weight: ${FONTS.WEIGHTMEDIUM};
  margin: 0 0 0.25rem 0;
`
// End Bordered Blocks Components

const BorderedBlocks = props => (
  <BlocksWrap
    style={{
      borderColor: props.borderColor,
    }}
  >
    {props.blocks.map(block => (
      <Block key={block.id} {...block} />
    ))}
  </BlocksWrap>
)

BorderedBlocks.propTypes = {
  blocks: PropTypes.array.isRequired,
}

const Block = props => (
  <BlockContent id={props.id}>
    <div>
      <BlockTitle>{props.colTitle}</BlockTitle>
      <Text>{props.colDesc}</Text>
    </div>
  </BlockContent>
)

Block.propTypes = {
  id: PropTypes.string.isRequired,
}

export default BorderedBlocks
