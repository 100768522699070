import styled from 'styled-components'
import FONTS from '../../../../constants/fonts'
import COLORS from '../../../../constants/colors'
import CustomIcon from '../../../Base/Icon/Custom'
import Text from '../../../Base/Text'

export const ModalsPricingWrapper = styled.div`
  max-width: 99rem;
  margin: auto;
`
export const ModalsPricingContent = styled.div`
  text-align: center;
`
export const ModalsPricingTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`
export const ModalIcon = styled(CustomIcon)`
  margin-right: 2rem;
`
export const ModalIntro = styled(Text)`
  max-width: 64rem;
  margin: 0 auto 2rem;
  display: inline-block;
`
export const Rates = styled.div`
  margin: 2rem 0 4rem;
  text-align: left;
`
export const RateRow = styled.div`
  padding: 2rem;
  display: flex;
  &:nth-child(even) {
    background-color: ${COLORS.GREYMEDIUM};
  }
`
export const RateTitle = styled.h3`
  font-size: ${FONTS.TEXTINTRO};
  display: inline-block;
  flex: 1;
  margin: 0;
`
export const RateData = styled.div`
  font-size: ${FONTS.TEXTDEFAULT};
  font-weight: ${FONTS.WEIGHTMEDIUM};
  display: inline-block;
  flex: 1;
  span {
    font-size: ${FONTS.TEXTSMALL};
    font-weight: ${FONTS.WEIGHTREGULAR};
  }
`
